import React from 'react';
import { Modal, Button, Input } from 'antd';
import { EditFilled } from '@ant-design/icons';

export default class TagsEdit extends React.Component {
  state = {
    visible: false,
    loading: false,
    value: '',
    valueEn: '',
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ visible: false });
    this.props.onItemEdit(this.props.id, { name: this.state.value, nameEn: this.state.valueEn });
  };

  handleCancel = e => {
    this.setState({ visible: false });
  };

  onChange = (e) => {
    this.setState({ value: e.target.value });
  }

  onChangeEn = (e) => {
    this.setState({ valueEn: e.target.value });
  }

  render() {
    return (
      <>
        <Button onClick={this.showModal}>
          <EditFilled />Редактировать тег
        </Button>
        <Modal
          title="Переименовать тег"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Отмена
            </Button>,
            <Button key="submit" type="primary" loading={this.state.loading} onClick={this.handleOk}>
              Переименовать
            </Button>,
          ]}
        >
          Новое имя:
          <br/>
          <Input style={{ width: '60%' }} value={this.value} onChange={this.onChange}/>

          <br/>
          <br/>

          English: Новое имя:
          <br/>
          <Input style={{ width: '60%' }} value={this.valueEn} onChange={this.onChangeEn}/>
        </Modal>
      </>
    );
  }
}
