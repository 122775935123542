import React from 'react';
import { Form, Input, Button, Switch } from 'antd';
import VideoUploader from '../../slides/video-uploader/VideoUploader';
import { apiUrl } from 'auth/api';

const layout = {
  wrapperCol: {
    span: 16,
  },
};

export default class InfoForm extends React.Component {
  onFinish = (values) => {
    this.props.onSubmit(values)
  }

  render() {
    const { videoUrl, show } = this.props.data

    return (
      <Form
        {...layout}
        name="basic"
        layout="vertical"
        className="form"
        onFinish={this.onFinish}
      >
        <Form.Item
          label="Загрузите видео"
          name="videoDataUri"
          rules={[
            {
              required: false,
              message: 'Зарузите ваше видео',
            },
          ]}
        >
          <VideoUploader video={videoUrl} action={`${apiUrl()}/admin/info/update_video`} />
        </Form.Item>

        <Form.Item
          name="show"
          label="Скрыть / показать"
        >
          <Switch defaultChecked={show} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={this.props.loading}>
            Обновить
          </Button>
        </Form.Item>
      </Form>
    )
  }
}
