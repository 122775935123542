import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import NewsList from './news-list/NewsList';
import NewsNew from './news-new/NewsNew';
import NewsEdit from './news-edit/NewsEdit';


export default function News() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <NewsList />
      </Route>
      <Route path={`${path}/new`}>
        <NewsNew />
      </Route>
      <Route path={`${path}/:news_id/edit`}>
        <NewsEdit />
      </Route>
    </Switch>
  )
}
