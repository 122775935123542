import React from 'react';
import api, { apiUrl } from 'auth/api'
import { Link } from 'react-router-dom';
import { PageHeader, Space, Table, Pagination } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import Delete from '../tags-delete/TagsDelete'
import Edit from '../tags-edit/TagsEdit'

const key = "tags-list";

export default class TagsList extends React.Component {
  state = {
    loading: false,
    items: null,
    totalItems: null,
    page: 1,
    perPage: 20,
    values: {},
  };

  componentDidMount() {
    this.loadTags(this.state);
  }

  render() {
    const columns = [
      {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name',
        render: text => <>{text}</>,
      },
      {
        title: 'Действия',
        key: 'action',
        render: (item) => (
          <Space size="middle">
            <Edit key='edit' id={item.id} onItemEdit={this.onItemEdit} />
            <Delete key='delete' id={item.id} onItemDeleted={this.onItemDeleted} />
          </Space>
        ),
      },
    ];
    const { items, totalItems } = this.state;
    return (
      <div>
        <PageHeader
          title="Список тегов"
          extra={[
            <Link key="1" to="/tags/new"><PlusOutlined />&nbsp;Добавить Тег</Link>,
          ]}
        />
        {this.state.loading && (
          <div>Загрузка...</div>
        )}
        <Table
          rowKey="id"
          columns={columns}
          dataSource={items}
          locale={{emptyText: "Нет тегов"}}
          pagination={false}
        />

        {items && <Pagination
          style={{marginTop: 12}}
          showSizeChanger
          onChange={this.onChangePage}
          pageSize={this.state.perPage}
          defaultCurrent={1}
          total={totalItems}
        />}
      </div>
    )
  }

  onChangePage = (page, perPage) => {
    this.setState({ page, perPage }, (_state) => {
      this.loadTags(this.state);
    });
  }

  onItemDeleted = (id) => {
    api.delete(`${apiUrl()}/admin/tags/${id}`, { })
      .then((_response) => {
        this.setState({ loading: false });
        message.success({ content: 'Тег успешно удален!', key, duration: 2 });
        this.loadTags(this.state);
      })
      .catch((_error) => {
        message.error({ content: 'Не удалось удалить тег!', key, duration: 2 });
        this.setState({ loading: false });
      });
  }

  onItemEdit = (id, values) => {
    api.put(`${apiUrl()}/admin/tags/${id}`, { tag: values })
      .then((_response) => {
        this.setState({ loading: false });
        message.success({ content: 'Тег обновлен!', key, duration: 2 });
        this.loadTags(this.state);
      })
      .catch((_error) => {
        message.error({ content: 'Не удалось переименовать тег!', key, duration: 2 });
        this.setState({ loading: false });
      });
  }

  loadTags(state) {
    this.setState({ loading: true });
    const { page, perPage } = state;

    api.get(`${apiUrl()}/admin/tags`, { params: { page, perPage } })
      .then((response) => {
        const { items, totalItems, } = response.data;
        this.setState({ loading: false, items, totalItems });
      })
      .catch((error) => {
        console.log("Error loading: " + error);
        this.setState({ loading: false, items: null });
      });
  }
}
