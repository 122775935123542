import React from 'react'
import { Form, Input, Button, Radio, Typography } from 'antd'

import './NewsForm.css'
import ImageUploader from '../../works/image-uploader/ImageUploader'
import transliterate from "../../../helpers/transliterate"
import { apiUrl, siteUrl } from 'auth/api'

import NewsPageForm from "../news-page-form/NewsPageForm"

const layout = {
  wrapperCol: {
    span: 16,
  },
}

const { TextArea } = Input

export default class NewsForm extends React.Component {
  constructor(props) {
    super(props)

    const { item } = props

    this.state = {
      item: item ? item : null,
      title: item ? item.title : null,
      titleEn: item ? item.titleEn : null,
      secondTitle: item ? item.secondTitle : null,
      secondTitleEn: item ? item.secondTitleEn : null,
      category: item ? item.category : 'internal',
      internal: item ? item.category === 'internal' : true,
      previewImage: null,
      mainImage: null,
      content: item ? item.content : null,
      contentEn: item ? item.contentEn : null,
      link: item ? item.link : null,
      sideImages: item ? item.sideImage : [],
      wallImages: item ? item.wallImage : []
    }

    this.onChangeContent = this.onChangeContent.bind(this)
    this.onChangeContentEn = this.onChangeContentEn.bind(this)
  }

  componentDidMount() {
    this.generateInnerLink()
  }

  addSideImages = (sideImages) => {
    this.setState({ sideImages })
  }

  addWallImages = (wallImages) => {
    this.setState({ wallImages })
  }

  setMainImage = (selectorFiles) => {
    this.toBase64(selectorFiles[0]).then((mainImage) => {
      this.setState({ mainImage })
    })
  }

  onChangeContent(e) {
    const content = e.target.value

    this.setState({ content })
  }

  onChangeContentEn(e) {
    const contentEn = e.target.value

    this.setState({ contentEn })
  }

  render() {
    const item = this.props.item

    const initialValues = {
      title: item ? item.title : null,
      titleEn: item ? item.titleEn : null,
      secondTitle: item ? item.secondTitle : null,
      secondTitleEn: item ? item.secondTitleEn : null,
      description: item ? item.description : null,
      descriptionEn: item ? item.descriptionEn : null,
      link: item ? item.link : null,
      content: item ? item.content : null,
      contentEn: item ? item.contentEn : null,
    }

    return (
      <React.Fragment>
        <Form
          { ...layout }
          name="basic"
          layout="vertical"
          initialValues={ initialValues }
          className="form"
          onFinish={ this.onFinish }
        >
          <Form.Item
            label="Заголовок новости"
            name="title"
            rules={ [
              {
                required: true,
                message: 'Введите заголовок новости',
              },
            ] }
          >
            <Input onChange={ this.onTitleChange } />
          </Form.Item>

          <Form.Item
            label="English: Заголовок новости"
            name="titleEn"
            rules={ [
              {
                required: true,
                message: 'Введите заголовок новости на английском',
              },
            ] }
          >
            <Input />
          </Form.Item>

          { this.isCreating() &&
            <Form.Item
              label="Превью изображение"
              name="previewImage"
              rules={ [
                {
                  required: true,
                  message: 'Новость не может быть без Превью',
                },
              ] }
            >
              <Input accept="image/*" type="file" onChange={ (e) => this.handleChange(e.target.files) } />
            </Form.Item>
          }

          { this.isEditing() && (
            <div className="works-form__preview-item">
              <div className="works-form__preview-label">Превью изображение</div>
              <ImageUploader
                image={ item.previewImage }
                action={ `${ apiUrl() }/admin/news/${ item.id }/update_preview_image` }
              />
            </div>
          ) }

          <Form.Item
            rows={ 6 }
            label="Описание"
            name="description"
          >
            <TextArea rows={ 6 } />
          </Form.Item>

          <Form.Item
            rows={ 6 }
            label="English: Описание"
            name="descriptionEn"
          >
            <TextArea rows={ 6 } />
          </Form.Item>

          <Form.Item>
            <Radio.Group defaultValue={ this.state.category } buttonStyle="solid">
              <Radio.Button onChange={ this.setInnerLink } value="internal">Внутренняя новость</Radio.Button>
              <Radio.Button onChange={ this.setOuterLink } value="external">Внешняя новость</Radio.Button>
            </Radio.Group>
          </Form.Item>

          {
            !this.state.internal && <Form.Item
              getValueProps={ (v) => v }
              label="Ссылка на новость"
              name="link"
              rules={ [
                {
                  required: true,
                  message: 'Введите ссылку на новость',
                },
                {
                  type: 'url',
                  message: 'Введи ссылку в формате: https://www.google.com'
                }
              ] }
            >
              <Input placeholder={ 'https://www.google.com/' } defaultValue={ this.state.link } />

            </Form.Item>
          }
          {
            this.state.internal && <Form.Item label={ 'Ссылка на внутренюю новость' }>
              <Typography>
              <pre>
                {
                  this.generateInnerLink()
                }
              </pre>
              </Typography>
            </Form.Item>
          }

          { this.state.internal && (
            <NewsPageForm
              item={ item }
              setMainImage={ this.setMainImage }
              addSideImages={ this.addSideImages }
              addWallImages={ this.addWallImages }
              onChangeContent={ this.onChangeContent }
              onChangeContentEn={ this.onChangeContentEn }
              onSecondTitleChange={ this.onSecondTitleChange }
              onSecondTitleEnChange={ this.onSecondTitleEnChange }
            />
          ) }

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={ this.props.loading }>
              { this.isCreating() ? 'Создать' : 'Обновить' }
            </Button>
          </Form.Item>
        </Form>

      </React.Fragment>
    )
  }

  setOuterLink = () => {
    this.setState({ internal: false, category: 'external' })
  }

  setInnerLink = () => {
    this.setState({ internal: true, category: 'internal' })
  }

  onTitleChange = (e) => {
    this.setState({ title: e.target.value })
  }

  onSecondTitleChange = (e) => {this.setState({ secondTitle: e.target.value })}

  onSecondTitleEnChange = (e) => {this.setState({ secondTitleEn: e.target.value })}

  generateInnerLink = () => {
    if (this.state.title == null) { return '' }

    const slug = this.generateSlug()

    return `${ siteUrl() }/news/${ slug }`
  }


  generateSlug = () => {

    return transliterate(this.state.title)
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '')
  }

  onFinish = (values) => {
    const toSubmit = this.isCreating()
      ? { ...values, previewImageDataUri: this.state.previewImage, mainImageDataUri: this.state.mainImage }
      : values

    const {
      internal,
      content,
      category,
      contentEn,
      secondTitle,
      secondTitleEn
    } = this.state

    if (internal) {
      const slug = this.generateSlug()
      const link = this.generateInnerLink()

      const images = this.isCreating() ? {
        sideImages: this.state.sideImages,
        wallImages: this.state.wallImages
      } : {}

      values = Object.assign(toSubmit, {
        slug,
        link,
        category,
        content,
        contentEn,
        secondTitle,
        secondTitleEn
      }, images)
    } else {
      values = Object.assign(toSubmit, {
        category
      })
    }
    console.log(values)
    this.props.onSubmit(values)
  }

  isEditing() {
    return this.props.item && this.props.item.id
  }

  isCreating() {
    return !this.isEditing()
  }

  handleChange = (selectorFiles) => {
    this.toBase64(selectorFiles[0]).then((previewImage) => {
      this.setState({ previewImage })
    })
  }

  toBase64 = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    }
  )
}
