import React from 'react';
import isAuthorized from 'auth/authorized-check'
import logout from 'auth/logout'
import Layout from '../layout/Layout'
import {
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom'

export default ({ path, Component, unreadMessages }) => {
  const history = useHistory()
  const onLogout = () => {
    logout()
    history.push('/login')
  }
  let collapsed = false
  const onCollapse = (value) => {
    collapsed = value
  }

  return <Route path={ path }>
    {isAuthorized()
      ? <Layout Component={ Component } onLogout={ onLogout } collapsed={ collapsed } onCollapse={ onCollapse } unreadMessages={ unreadMessages } />
      : <Redirect to="/login" />
    }
  </Route>
}
