import React from 'react';
import { Modal, Button, Input, Form } from 'antd';
import ImageUploader from '../../image-uploader/ImageUploader';
import { apiUrl } from 'auth/api';

export default class FreezeFramesModal extends React.Component {
  state = {
    image: null
  }

  render() {
    let { modalItem } = this.props;
    const initialValues = {
      name: modalItem ? modalItem.name : null,
      nameEn: modalItem ? modalItem.nameEn : null,
      videoUrl: modalItem ? modalItem.videoUrl : null,
      image: null,
    }
    return (
      <Modal
        title="Стоп-кадр"
        visible={!!modalItem}
        onCancel={this.props.onModalHide}
        footer={false}
      >
        <Form
          name="freeze-frames-form"
          onFinish={this.onFinish}
          layout="vertical"
          initialValues={initialValues}
        >
          <Form.Item
            label="Название кадра"
            name="name"
            rules={[
              {
                required: true,
                message: 'Введите название кадра',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="English: Название кадра"
            name="nameEn"
            rules={[
              {
                required: true,
                message: 'Введите название кадра на английском',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Ссылка на видео"
            name="videoUrl"
            rules={[
              {
                required: true,
                message: 'Введите ссылку на видео',
              },
            ]}
          >
            <Input />
          </Form.Item>

          {this.isCreating() &&
            <Form.Item
              label="Превью изображение"
              name="image"
              rules={[
                {
                  required: true,
                  message: 'Стоп-кадр не может быть без Превью',
                },
              ]}
            >
              <Input type="file" onChange={ (e) => this.handleChange(e.target.files) } />
            </Form.Item>
          }

          {this.isEditing() &&
            <div className="works-form__preview-item">
              <div className="works-form__preview-label">Превью изображение</div>
              <ImageUploader image={modalItem.image} action={`${apiUrl()}/admin/freeze_frames/${modalItem.id}/update_image`}  />
            </div>
          }

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={this.props.loading}>
              {this.isCreating() ? "Добавить" : "Обновить"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  isCreating = () => {
    return this.props.modalItem && !this.props.modalItem.id;
  }

  isEditing = () => {
    return this.props.modalItem && !!this.props.modalItem.id;
  }

  onFinish = (values) => {
    const toSubmit = this.isCreating() ? { ...values, imageDataUri: this.state.image } : values;
    this.props.onModalSave(this.props.modalItem.id, toSubmit);
  }

  handleChange(selectorFiles) {
    this.toBase64(selectorFiles[0]).then((image) => {
      this.setState({ image });
    });
  }

  // TODO: fix copypaste
  toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
