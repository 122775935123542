import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import TalentsList from './talents-list/TalentsList';
import TalentsNew from './talents-new/TalentsNew';
import TalentsEdit from './talents-edit/TalentsEdit';


export default function Talents() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <TalentsList />
      </Route>
      <Route path={`${path}/new`}>
        <TalentsNew />
      </Route>
      <Route path={`${path}/:talent_id/edit`}>
        <TalentsEdit />
      </Route>
    </Switch>
  )
}
