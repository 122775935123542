import React from 'react';
import api from 'auth/api';
import { PageHeader } from 'antd';
import { message, Form, Input, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { apiUrl } from 'auth/api';

const key = "clients-new";

class ClientsNew extends React.Component {
  state = {
    loading: false,
    value: '',
  };

  redirect = () => {
    this.props.history.push('/clients');
  };

  onChange = (e) => {
    this.setState({ value: e.target.value });
  }

  onSubmit = () => {
    message.loading({ content: 'Загрузка...', key });
    this.setState({ loading: true });
    api.post(`${apiUrl()}/admin/clients`, { client: { name: this.state.value } })
      .then((_response) => {
        this.setState({ loading: false });
        message.success({ content: 'Клиент создан!', key, duration: 2 });
        this.redirect();
      })
      .catch((error) => {
        message.error({ content: 'Не удалось создать Клиента', key, duration: 2 });
        console.log(`Error: ${error}`);
        this.setState({ loading: false });
      });
  };


  render() {
    return (
      <div>
        <PageHeader
          title="Назад к списку" onBack={this.redirect}
        />
          <Form name="Создать нового клиента" onFinish={this.onSubmit}>
            Имя клиента:
              <Form.Item name="tangame" rules={[{ required: true, message: 'Имя не может быть пустым' }]}>
                <Input style={{ width: '60%', marginTop: '10px' }} value={this.value} onChange={this.onChange}/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ marginLeft: '6px' }}>
                  Создать
                </Button>
              </Form.Item>
          </Form>
      </div>
    )
  }
}

export default withRouter(ClientsNew);
