import React from 'react'
import { Form, Input, Button } from 'antd'
import axios from 'axios'

import ImageUploader from '../../works/image-uploader/ImageUploader'
import { apiUrl, authHeader } from 'auth/api'

import PicturesWallUploader from "../pictures-wall-uploader/PicturesWallUploader"

const layout = {
  wrapperCol: {
    span: 16,
  },
}

const { TextArea } = Input

export default class NewsPageForm extends React.Component {
  constructor(props) {
    super(props)

    const { item } = props

    this.state = {
      item,
      previewImage: null,
      sideImages: item ? item.sideImages : [],
      wallImages: item ? item.wallImages : []
    }
  }

  addSideImages = (sideImages) => {
    this.setState({ sideImages })
  }

  addWallImages = (wallImages) => {
    this.setState({ wallImages })
  }

  addSideImagesWhenCreating = async (files) => {
    let base64Files = []

    const promises = Object.values(files).map(file => this.toBase64(file))

    await Promise.all(promises).then((files) => {
      base64Files = files
    })

    this.props.addSideImages(base64Files)
  }

  addWallImagesWhenCreating = async (files) => {
    let base64Files = []

    const promises = Object.values(files).map(file => this.toBase64(file))

    await Promise.all(promises).then((files) => {
      base64Files = files
    })

    this.props.addWallImages(base64Files)
  }

  onRemoveSideImage = (file) => {
    const item = this.props.item
    const imagePosition = this.state.sideImages.indexOf(file)

    axios.delete(`${ apiUrl() }/admin/news/${ item.id }/remove_side_image/${ imagePosition }`, {
      headers: {
        'Authorization': authHeader()
      }
    })
  }

  onRemoveWallImage = (file) => {
    const item = this.props.item
    const imagePosition = this.state.wallImages.indexOf(file)

    axios.delete(`${ apiUrl() }/admin/news/${ item.id }/remove_wall_image/${ imagePosition }`, {
      headers: {
        'Authorization': authHeader()
      }
    })
  }

  isEditing() {
    return this.props.item && this.props.item.id
  }

  isCreating() {
    return !this.isEditing()
  }

  setMainImage = (selectorFiles) => {
    this.toBase64(selectorFiles[0]).then((mainImage) => {
      this.setState({ mainImage })
    })
  }

  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  render() {
    const item = this.props.item

    const initialValues = {}

    return (
      <React.Fragment>
        <Form.Item
          label="Второй заголовок новости"
          name="secondTitle"
          rules={ [
            {
              required: false,
            },
          ] }
        >
          <Input onChange={ this.props.onSecondTitleChange } />
        </Form.Item>
        <Form.Item
          label="English: Второй заголовок новости"
          name="secondTitleEn"
          rules={ [
            {
              required: false,
            },
          ] }
        >
          <Input onChange={ this.props.onSecondTitleEnChange } />
        </Form.Item>
        {this.isCreating() && (
          <Form.Item
            label="Главное изображение"
            name="mainImage"
            rules={ [
              {
                required: true,
                message: 'Новость не может быть без изображения',
              },
            ] }
          >
            <Input accept="image/*" type="file" onChange={ (e) => this.props.setMainImage(e.target.files) } />
          </Form.Item>
        )}

        {this.isEditing() && (
          <div className="works-form__preview-item">
            <div className="works-form__preview-label">Главное изображение</div>
            <ImageUploader
              image={ item.mainImage }
              action={ `${ apiUrl() }/admin/news/${ item.id }/update_main_image` }
            />
          </div>
        )}

        <Form.Item
          rows={ 6 }
          label="Основной текст"
          name="content"
          rules={ [
            {
              required: true,
              message: 'Новость не может быть без текста',
            }
          ] }
          onChange={ this.props.onChangeContent }
        >
          <TextArea rows={ 6 } />
        </Form.Item>

        <Form.Item
          rows={ 6 }
          label="English: основной текст"
          name="contentEn"
          onChange={ this.props.onChangeContentEn }
        >
          <TextArea rows={ 6 } />
        </Form.Item>

        <Form.Item
          label="Загрузите 2 изображения"
          name="sideImage"
        >
          {this.isEditing() && (
            <PicturesWallUploader
              name="sideImage"
              fileList={ item ? item.sideImages : [] }
              action={ `${ apiUrl() }/admin/news/${ item.id }/create_side_image` }
              onChange={ this.addSideImages }
              onRemove={ this.onRemoveSideImage }
            />
          )}

          {this.isCreating() && (
            <Input
              type="file"
              name="sideImages"
              accept="image/*"
              onChange={ (e) => this.addSideImagesWhenCreating(e.target.files) }
              multiple
            />
          )}
        </Form.Item>

        <Form.Item
          label="Загрузите изображения"
          name="wallImage"
        >
          {this.isEditing() && (
            <PicturesWallUploader
              name="wallImage"
              imgQuantity={ 100 }
              fileList={ item ? item.wallImages : [] }
              action={ `${ apiUrl() }/admin/news/${ item.id }/create_wall_image` }
              onChange={ this.addWallImages }
              onRemove={ this.onRemoveWallImage }
            />
          )}

          {this.isCreating() && (
            <Input
              type="file"
              accept="image/*"
              onChange={ (e) => this.addWallImagesWhenCreating(e.target.files) }
              multiple
            />
          )}
        </Form.Item>
      </React.Fragment>
    )
  }
}
