import React from 'react';
import api, { apiUrl } from 'auth/api'
import { Link } from 'react-router-dom';
import { PageHeader, Space, Table, Input } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { message } from 'antd';
import Delete from '../talents-delete/TalentsDelete';

const { Search } = Input;

const key = "talents-list";

export default class TalentsList extends React.Component {
  state = {
    loading: false,
    items: [],
    searchName: null
  };

  componentDidMount() {
    this.loadTalents();
  }

  render() {
    const columns = [
      {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name',
        render: text => <span>{text}</span>,
      },
      {
        title: 'Фото',
        key: 'photo',
        render: (item) => <img src={item.photo} alt="Фото" style={{maxHeight: 50}} />
      },
      {
        title: 'Роль',
        dataIndex: 'roleTranslated',
        key: 'role',
        render: text => <span>{text}</span>,
      },
      {
        title: 'Действия',
        key: 'action',
        render: (item) => (
          <Space size="middle">
            <Link key="edit" to={`/talents/${item.id}/edit`}><EditOutlined key="edit" />&nbsp;Редактировать</Link>
            <Delete key='delete' id={item.id} onItemDeleted={this.onItemDeleted} />
          </Space>
        ),
      },
    ];

    const filters =  <div className="list__filters">
      <Search
        placeholder="Поиск по имени"
        onSearch={this.onSearch}
        enterButton="Искать"
        size="large"
      />
    </div>;

    const { items } = this.state;
    return (
      <div>
        <PageHeader
          title="Список талантов"
          extra={[
            <Link key="1" to="/talents/new"><PlusOutlined />&nbsp;Добавить талант</Link>,
          ]}
        />
        {this.state.loading && (
          <div>Загрузка...</div>
        )}
        {filters}
        <Table
          rowKey="id"
          columns={columns}
          dataSource={items}
          locale={{emptyText: "Нет ни одного таланта"}}
          pagination={false}
        />
      </div>
    )
  }

  onSearch = (searchName) => {
    this.setState({ searchName }, () => this.loadTalents(this.state));
  }

  onItemDeleted = (id) => {
    api.delete(`${apiUrl()}/admin/talents/${id}`, { })
      .then((_response) => {
        this.setState({ loading: false });
        message.success({ content: 'Талант успешно удален!', key, duration: 2 });
        this.loadTalents();
      })
      .catch((_error) => {
        message.error({ content: 'Не удалось удалить талант!', key, duration: 2 });
        this.setState({ loading: false });
      });
  }

  loadTalents() {
    this.setState({ loading: true });
    const { searchName } = this.state;

    api.get(`${apiUrl()}/admin/talents`, { params: { searchName } })
      .then((response) => {
        const { items } = response.data;
        this.setState({ loading: false, items });
      })
      .catch((error) => {
        console.log("Error loading: " + error);
        this.setState({ loading: false, items: null });
      });
  }
}
