import React from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import { Layout, Menu, Button, Badge } from 'antd'
import {
  PlaySquareOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
  PictureOutlined,
  TagsOutlined,
  MailOutlined,
  VideoCameraOutlined,
  ContainerOutlined
} from '@ant-design/icons'
import './Layout.css'

const { Content, Sider } = Layout
const { SubMenu } = Menu

export default withRouter(({ location, Component, collapsed, onCollapse, onLogout, unreadMessages }) => {
  return (
    <Layout style={ { minHeight: '100vh' } } className="site-layout">
      <Layout>
        <Sider width={ 270 } collapsed={ collapsed } onCollapse={ onCollapse }>
          <Menu
            mode="inline"
            openKeys={ ['/landing'] }
            selectedKeys={ [location.pathname] }
            style={ { height: '100%', borderRight: 0 } }
          >
            <li className="logo">Панель администратора</li>
            <Menu.Item key="/works" icon={ <VideoCameraOutlined /> }>
              <Link to="/works">Работы</Link>
            </Menu.Item>
            <Menu.Item key="/slides" icon={ <PlaySquareOutlined /> }>
              <Link to="/slides">Слайды</Link>
            </Menu.Item>
            <SubMenu openKeys={ ['/landing'] } key="/landing" icon={ <PictureOutlined /> } title="Лендинг">
              <Menu.Item key="/landing/featured_order">
                <Link to="/landing/featured_order">Порядок Featured работ</Link>
              </Menu.Item>
              <Menu.Item key="/news">
                <Link to="/news">Новости</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="/tags" icon={ <TagsOutlined /> }>
              <Link to="/tags">Тэги</Link>
            </Menu.Item>
            <Menu.Item key="/talents" icon={ <UsergroupAddOutlined /> }>
              <Link to="/talents">Таланты</Link>
            </Menu.Item>
            <Menu.Item key="/clients" icon={ <UserOutlined /> }>
              <Link to="/clients">Клиенты</Link>
            </Menu.Item>
            <Menu.Item key="/messages" icon={ <MailOutlined /> }>
              <Badge offset={ [20, 0] } count={ unreadMessages }><Link to="/messages">Обращения</Link></Badge>
            </Menu.Item>
            <Menu.Item key="/info" icon={ <ContainerOutlined /> }>
              <Badge offset={ [20, 0] }><Link to="/info">Инфо</Link></Badge>
            </Menu.Item>
            <Menu.Item key="/pages" icon={ <ContainerOutlined /> }>
              <Badge offset={ [20, 0] }><Link to="/pages">Скрытие страниц</Link></Badge>
            </Menu.Item>
            <Menu.Item style={ { marginTop: 64 } }>
              <Button type="dashed" onClick={ onLogout } icon={ <LogoutOutlined /> }>Выйти</Button>
            </Menu.Item>
          </Menu>
        </Sider>

        <Layout style={ { padding: '0 24px 24px' } }>
          <Content
            style={ { margin: '0 16px' } }
          >
            { Component }
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
})
