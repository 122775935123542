import React from 'react'
import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom'

import InfoEdit from './info-edit/InfoEdit'

export default function Info() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={ path }>
        <InfoEdit />
      </Route>
    </Switch>
  )
}
