import React from 'react';
import api, { apiUrl } from 'auth/api';
import WorksDragTable from '../works-drag-table/WorksDragTable';
import { PageHeader, message } from 'antd';

const key = "featured-order";

export default class FeaturedOrder extends React.Component {
  state = { items: null }

  componentDidMount() {
    api.get(`${apiUrl()}/admin/works/featured`).then((response) => {
      const items = response.data.items;
      this.setState({ items });
    });
  }

  onSortEnd = (newPositions) => {
    api.put(`${apiUrl()}/admin/works/reorder_featured`, { newPositions }).then((_response) => {
      message.success({ content: 'Порядок изменен', key, duration: 1 });
    });
  }

  render = () => {
    const { items } = this.state;
    return <div>
      <PageHeader title="Изменить порядок Featured работ" />
      {items && <WorksDragTable items={items} onSortEnd={this.onSortEnd} />}
    </div>
  }
}
