import React from 'react';
import api from 'auth/api';
import { withRouter } from 'react-router-dom';
import WorksContentForm from '../works-content-form/WorksContentForm';
import { apiUrl } from 'auth/api';
import { Typography } from 'antd';

const { Title } = Typography;

class WorksAddContent extends React.Component {
  state = {
    item: null,
  };

  componentDidMount() {
    this.onReload();
  }

  render() {
    return <>
        <Title level={3} style={{marginTop: 24}}>Редактирование контента для работы</Title>
        {this.state.item && <WorksContentForm item={this.state.item} />}
    </>
  }

  // TODO: fix copypaste
  onReload = () => {
    const { work_id } = this.props.match.params;
    api.get(`${apiUrl()}/admin/works/${work_id}`)
      .then((response) => {
        this.setState({ item: response.data.item });
      })
      .catch((error) => {
        console.log("Error: " + error);
        this.setState({ item: null });
      });
  }
}

export default withRouter(WorksAddContent);
