import React from 'react';
import api from 'auth/api';
import { PageHeader } from 'antd';
import { message } from 'antd';
import { withRouter } from 'react-router-dom';
import { apiUrl } from 'auth/api';
import TalentsForm from '../talents-form/TalentsForm';

const key = "talents-edit";

class TalentsEdit extends React.Component {
  state = {
    item: null,
    loading: false,
  };

  redirect = () => {
    this.props.history.push('/talents');
  };

  componentDidMount() {
    const { talent_id } = this.props.match.params;
    this.setState({ loading: true });
    api.get(`${apiUrl()}/admin/talents/${talent_id}`)
      .then((response) => {
        this.setState({ loading: false, item: response.data.item });
      })
      .catch((error) => {
        console.log("Error loading: " + error);
        this.setState({ loading: false, item: null });
      });
  }

  onSubmit = (values) => {
    message.loading({ content: 'Загрузка...', key });
    this.setState({ loading: true });
    api.put(`${apiUrl()}/admin/talents/${this.state.item.id}`, { talent: values })
      .then((_response) => {
        this.setState({ loading: false });
        message.success({ content: 'Талант обновлён!', key, duration: 2 });
        this.redirect();
      })
      .catch((error) => {
        message.error({ content: 'Не удалось обновить Талант', key, duration: 2 });
        console.log(`Error: ${error}`);
        this.setState({ loading: false });
      });
  };

  render() {
    const item = this.state.item;
    return <div>
      <PageHeader title="Назад к списку" onBack={this.redirect}/>
      {item && <TalentsForm item={item} onSubmit={this.onSubmit} />}
    </div>
  }
}

export default withRouter(TalentsEdit);
