import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import SlidesList from './slides-list/SlidesList';
import SlidesNew from './slides-new/SlidesNew';
import SlidesEdit from './slides-edit/SlidesEdit';

export default function Slides() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <SlidesList />
      </Route>
      <Route path={`${path}/new`}>
        <SlidesNew />
      </Route>
      <Route path={`${path}/:slide_id/edit`}>
        <SlidesEdit />
      </Route>
    </Switch>
  )
}
