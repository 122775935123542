import React from 'react';
import api, { apiUrl } from 'auth/api'
import { Link } from 'react-router-dom';
import { PageHeader, Empty, Typography } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Pagination, Input } from 'antd';
import { message } from 'antd';
import WorkListItem from './WorkListItem';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const { Search } = Input;
const { Paragraph } = Typography;

export default class WorksList extends React.Component {
  state = {
    loading: false,
    items: null,
    totalItems: null,
    page: 1,
    perPage: 20,
    searchName: null
  };

  componentDidMount() {
    this.loadWorks(this.state);
  }

  renderList() {
    const { items } = this.state;

    const SortableItem = SortableElement(({ value, index }) => {
      return (
        <WorkListItem
          index={index}
          key={value.id}
          work={value}
          onItemDeleted={this.onItemDeleted}
        />
      )
    })

    const SortableList = SortableContainer(({ items }) => {
      return (
        <div className="list__container">
          {items.map((work, index) => (
            <SortableItem index={index} key={work.id} value={work} />
          ))}
        </div>
      )
    })

    const onSortEnd = ({ oldIndex, newIndex }) => {
      this.setState(({ items }) => ({
        items: arrayMove(items, oldIndex, newIndex)
      }));

      const { page, perPage } = this.state;

      const offset = (page - 1) * perPage;
      const recalculatedOldIndex = oldIndex + offset + 1;
      const recalculatedNewIndex = newIndex + offset + 1;
      this.reorder(recalculatedOldIndex, recalculatedNewIndex);
    }

    return (
      <SortableList items={items} axis="xy" pressDelay={200} onSortEnd={onSortEnd} />
    )
  }

  render() {
    const { items, totalItems } = this.state;

    const header = <PageHeader
      title="Список работ"
      extra={[
        <Link key="1" to="/works/new"><PlusOutlined />&nbsp;Добавить Работу</Link>,
      ]}
    />;

    const filters =  <div className="list__filters">
      <Search
        placeholder="Поиск по имени Работы"
        onSearch={this.onSearch}
        enterButton="Искать"
        size="large"
      />
    </div>;

    const loading = <>{this.state.loading && (<div>Загрузка...</div>)}</>;

    const emptyState = <>{items && !items.length && <Empty description="Нет работ" image={Empty.PRESENTED_IMAGE_SIMPLE} />}</>;

    const pagination = <>{items && <Pagination
      style={{marginTop: 12}}
      showSizeChanger
      onChange={this.onChangePage}
      pageSize={this.state.perPage}
      defaultCurrent={1}
      total={totalItems}
    />}</>;

    const hintText = <Paragraph style={{marginTop: 32}}>
      <ExclamationCircleOutlined style={{color: 'rgba(255, 0, 0, .5)'}} />
      &nbsp;&nbsp;Красным бейджем помечены работы, которые показываются на Featured секции лендинга
    </Paragraph>

    return <>
      {header}
      {filters}
      {loading}
      {emptyState}
      {items && this.renderList()}
      {pagination}
      {hintText}
    </>
  }

  onChangePage = (page, perPage) => {
    this.setState({ page, perPage }, () => this.loadWorks(this.state));
  }

  onSearch = (searchName) => {
    this.setState({ searchName }, () => this.loadWorks(this.state));
  }

  onItemDeleted = (id) => {
    this.deleteWork(id);
  }

  reorder(oldIndex, newIndex) {
    this.setState({ loading: true });
    api.put(`${apiUrl()}/admin/works/reorder`, { oldIndex, newIndex })
      .then((response) => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log("Error loading: " + error);
        this.setState({ loading: false, items: null, totalItems: null });
      });
  }

  loadWorks(state) {
    this.setState({ loading: true });
    const { page, perPage, searchName } = state;

    api.get(`${apiUrl()}/admin/works`, { params: { page, perPage, searchName } })
      .then((response) => {
        const { items, totalItems, } = response.data;
        this.setState({ loading: false, items, totalItems });
      })
      .catch((error) => {
        console.log("Error loading: " + error);
        this.setState({ loading: false, items: null, totalItems: null });
      });
  }

  deleteWork = (id) => {
    api.delete(`${apiUrl()}/admin/works/${id}`, { })
      .then((_response) => {
        this.setState({ loading: false });
        message.success({ content: 'Работа успешно удалена!', key: "works-delete", duration: 2 });
        this.loadWorks(this.state)
      })
      .catch((_error) => {
        message.error({ content: 'Не удалось удалить работу!', key: "works-delete", duration: 2 });
        this.setState({ loading: false });
      });
  }
}
