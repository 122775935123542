import React from 'react'
import { Form, Input, Button, Radio, Checkbox } from 'antd'
import { apiUrl } from 'auth/api'
import './TalentsForm.css'
import ImageUploader from '../../works/image-uploader/ImageUploader'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

const layout = {
  wrapperCol: {
    span: 16,
  },
};

const { TextArea } = Input;

export default class TalentsForm extends React.Component {
  state = {
    item: null,
    photo: null,
    initialSocials: null,
  };

  componentDidMount() {
    const item = this.props.item;

    // нужно потом для проставления флагов на удаление
    let initialSocials = [];
    if (item) {
      initialSocials = item.socials;
    }
    this.setState({ initialSocials });
  }

  render() {
    const item = this.props.item;
    const initialValues = {
      name: item ? item.name : null,
      nameEn: item ? item.nameEn : null,
      role: item ? item.role : 'director',
      agency: item ? item.agency : null,
      agencyLink: item ? item.agencyLink : null,
      bio: item ? item.bio : null,
      bioEn: item ? item.bioEn : null,
      archived: item ? item.archived : false,
      socialsAttributes: item ? item.socials || [] : []
    };

    return (
      <Form
        {...layout}
        name="basic"
        layout="vertical"
        initialValues={initialValues}
        className="form"
        onFinish={this.onFinish}
      >
        <Form.Item
          label="Имя таланта"
          name="name"
          rules={[
            {
              required: true,
              message: 'Введите имя',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="English: Имя таланта"
          name="nameEn"
          rules={[
            {
              required: true,
              message: 'Введите имя',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Профессия" name="role">
          <Radio.Group>
            <Radio.Button value={'director'}>Директор</Radio.Button>
            <Radio.Button value={'dp'}>DP</Radio.Button>
            <Radio.Button value={'producer'}>Продюсер</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {this.isCreating() &&
          <Form.Item
            label="Фото"
            name="photo"
            rules={[
              {
                required: true,
                message: 'Талант не может быть без фото',
              },
            ]}
          >
            <Input type="file" onChange={ (e) => this.handleChange(e.target.files) } />
          </Form.Item>
        }

        {this.isEditing() &&
          <div className="works-form__preview-item">
            <div className="works-form__preview-label">Фото</div>
            <ImageUploader image={item.photo} action={`${apiUrl()}/admin/talents/${item.id}/update_photo`} />
          </div>
        }

        <Form.Item label="Агентство" name="agency">
          <Input />
        </Form.Item>

        <Form.Item label="Ссылка на агентство (опционально)" name="agencyLink">
          <Input />
        </Form.Item>

        <Form.Item
          rows={8}
          label="Bio"
          name="bio"
        >
          <TextArea rows={10} />
        </Form.Item>

        <Form.Item
          rows={8}
          label="English: Bio"
          name="bioEn"
        >
          <TextArea rows={10} />
        </Form.Item>

        <div style={{marginTop: 16, marginBottom: 8}}>Соц. сети</div>
        <Form.List name="socialsAttributes">
          {(fields, { add, remove }) => (<>
            {fields.map((field, index) => (<div key={index} style={{ marginTop: 16 }}>
              <Form.Item
                validateTrigger={['onChange', 'onBlur']}
                name={[field.name, "name"]}
                rules={[ { required: true, whitespace: true, message: "Пожалуйста введите название соц. сети" } ]}
                noStyle
                key={index + "_name"}
              >
                <Input placeholder="название соц. сети" style={{ width: '60%' }} />
              </Form.Item>

              <Form.Item
                name={[field.name, "url"]}
                validateTrigger={['onChange', 'onBlur']}
                noStyle
                key={index + "_url"}
              >
                <Input placeholder="ссылка на соц. сеть" style={{ width: '60%' }} />
              </Form.Item>

              {fields.length > 1 ? (
                <MinusCircleOutlined
                  style={{marginLeft: 8}}
                  className="dynamic-delete-button"
                  onClick={() => remove(field.name)}
                />
              ) : null}
            </div>))}
            <Form.Item style={{marginTop: 16}}>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '60%' }}
                icon={<PlusOutlined />}
              >
                Добавить соц. сеть
              </Button>
            </Form.Item>
          </>)}
        </Form.List>

        <Form.Item name="archived" valuePropName="checked">
          <Checkbox>В архиве (Скрыт со страницы талантов)</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={this.props.loading}>
            {this.isCreating() ? "Создать" : "Обновить"}
          </Button>
        </Form.Item>
      </Form>)
  }

  onFinish = (values) => {
    let toSubmit = this.isCreating() ? { ...values, photoDataUri: this.state.photo } : values;
    toSubmit = this.isEditing() ? { ...values, socialsAttributes: this.markForDestroy(values.socialsAttributes) } : values;
    this.props.onSubmit(toSubmit);
  };

  isEditing() {
    return this.props.item && this.props.item.id;
  }

  isCreating() {
    return !this.isEditing();
  }

  handleChange(selectorFiles) {
    this.toBase64(selectorFiles[0]).then((photo) => {
      this.setState({ photo });
    });
  }

  toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  markForDestroy = (socials) => {
    const result = [];
    socials.forEach(social => result.push(social));
    this.state.initialSocials.forEach(initialSocial => {
      if (!socials.find(social => social.id && social.id === initialSocial.id)) {
        const withDestroyFlag = initialSocial;
        withDestroyFlag['_destroy'] = '1'; // rails nested attributes syntax for destoying
        result.push(withDestroyFlag);
      }
    });

    return result;
  }
}
