import React from 'react'
import api from 'auth/api'
import { PageHeader } from 'antd'
import { message } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { apiUrl } from 'auth/api'
import NewsForm from '../news-form/NewsForm'
import { PlusOutlined } from "@ant-design/icons"

const key = "news-edit"

class NewsEdit extends React.Component {
  state = {
    item: null,
    loading: false,
  }

  redirect = () => {
    this.props.history.push('/news')
  }

  componentDidMount() {
    const { news_id } = this.props.match.params
    this.setState({ loading: true })
    api.get(`${ apiUrl() }/admin/news/${ news_id }`)
      .then((response) => {
        this.setState({ loading: false, item: response.data.item })
      })
      .catch((error) => {
        console.log("Error loading: " + error)
        this.setState({ loading: false, item: null })
      })
  }

  onSubmit = (values) => {
    message.loading({ content: 'Загрузка...', key })
    this.setState({ loading: true })
    api.put(`${ apiUrl() }/admin/news/${ this.state.item.id }`, { news: values })
      .then((_response) => {
        this.setState({ loading: false })
        message.success({ content: 'Новость обновлена!', key, duration: 2 })
        this.redirect()
      })
      .catch((error) => {
        message.error({ content: 'Не удалось обновить новость', key, duration: 2 })
        console.log(`Error: ${ error }`)
        this.setState({ loading: false })
      })
  }

  render() {
    const item = this.state.item

    return <div>
      <PageHeader title="Назад к списку" onBack={ this.redirect } />
      { item && <NewsForm item={ item } onSubmit={ this.onSubmit } /> }
    </div>
  }
}

export default withRouter(NewsEdit)
