import axios from 'axios'
import { message } from 'antd'

export const TOKEN_NAME = 'authToken'
/*global process*/
export const apiUrl = () => process.env.REACT_APP_API_URL

export const siteUrl = () => process.env.REACT_APP_SITE_URL

const authToken = () => localStorage.getItem(TOKEN_NAME)

const service = axios.create({
  timeout: 20000
})

export const authHeader = () => `Bearer ${authToken()}`

service.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = authHeader()
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use((response) => {
  return response;
}, (error) => {
  const { response } = error;

  if(response.status === 401 && response.config.url && response.config.url.includes(apiUrl())) {
    message.success({ content: 'Пожалуйста, войдите в систему', key: "please-login", duration: 2 })
    localStorage.removeItem(TOKEN_NAME)
    window.location = '/login'
  } else {
    return response;
  }
});

export default service
