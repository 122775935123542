import React from 'react';
import { Modal, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export default class TagsDelete extends React.Component {
  state = {
    visible: false,
    loading: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ visible: false });
    this.props.onItemDeleted(this.props.id);
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Button onClick={this.showModal}>
          <DeleteOutlined />Удалить тег
        </Button>
        <Modal
          title="Подтвердите удаление"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          cancelText="Нет"
          okText="Да, удалить"
        >
          <p>Вы точно хотите удалить этот тег?</p>
        </Modal>
      </>
    );
  }
}
