import React from 'react';
import api, { apiUrl } from 'auth/api'
import { Link } from 'react-router-dom';
import { PageHeader, Space, Table } from 'antd';
import { PlusOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons';
import { message } from 'antd';
import Delete from '../news-delete/NewsDelete';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';


const key = "news-list";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

export default class NewsList extends React.Component {
  state = {
    loading: false,
    items: [],
  };

  componentDidMount() {
    this.loadNews();
  }

  render() {
    const columns = [
      {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
      },
      {
        title: 'Название ',
        dataIndex: 'title',
        key: 'title',
        render: text => <span>{text}</span>,
      },
      {
        title: 'Действия',
        key: 'action',
        render: (item) => (
          <Space size="middle">
            <Link key="edit" to={`/news/${item.id}/edit`}><EditOutlined key="edit" />&nbsp;Редактировать</Link>
            <Delete key='delete' id={item.id} onItemDeleted={this.onItemDeleted} />
          </Space>
        ),
      },
    ];

    const DraggableContainer = props => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );

    const { items } = this.state;
    return (
      <div>
        <PageHeader
          title="Список новостей"
          extra={[
            <Link key="1" to="/news/new"><PlusOutlined />&nbsp;Добавить новость</Link>,
          ]}
        />
        {this.state.loading && (
          <div>Загрузка...</div>
        )}
        <Table
          rowKey="id"
          columns={columns}
          dataSource={items}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: this.DraggableBodyRow,
            },
          }}
          locale={{emptyText: "Нет новостей"}}
          pagination={false}
        />
      </div>
    )
  }

  onItemDeleted = (id) => {
    api.delete(`${apiUrl()}/admin/news/${id}`, { })
      .then((_response) => {
        this.setState({ loading: false });
        message.success({ content: 'Новость успешно удалена!', key, duration: 2 });
        this.loadNews();
      })
      .catch((_error) => {
        message.error({ content: 'Не удалось удалить новость!', key, duration: 2 });
        this.setState({ loading: false });
      });
  }

  loadNews() {
    this.setState({ loading: true });

    api.get(`${apiUrl()}/admin/news`)
      .then((response) => {
        const { items, } = response.data;
        this.setState({ loading: false, items });
      })
      .catch((error) => {
        console.log("Error loading: " + error);
        this.setState({ loading: false, items: null });
      });
  }

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { items } = this.state;
    const index = items.findIndex(x => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { items } = this.state;
    if (oldIndex !== newIndex) {
      const newItems = arrayMove([].concat(items), oldIndex, newIndex).filter(el => !!el);
      this.setState({ items: newItems });
      const newPositions = newItems.map((value, index) => ({ id: value.id, index }));
      this.updateReorderedPositions(newPositions);
    }
  };

  updateReorderedPositions = (newPositions) => {
    api.put(`${apiUrl()}/admin/news/reorder`, { newPositions }).then((_response) => {
      message.success({ content: 'Порядок изменен', key, duration: 1 });
    });
  }
}
