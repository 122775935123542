import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import TagsList from './tags-list/TagsList';
import TagsNew from './tags-new/TagsNew';


export default function Tags() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <TagsList />
      </Route>
      <Route path={`${path}/new`}>
        <TagsNew />
      </Route>
    </Switch>
  )
}
