import React from 'react'
import 'antd/dist/antd.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import api, { apiUrl } from 'auth/api'
import isAuthorized from 'auth/authorized-check'
import PrivateRoute from './PrivateRoute'
import './App.css'
import Login from '../login/Login'
import Info from '../private/info/Info'
import Works from '../private/works/Works'
import Tags from '../private/tags/Tags'
import Landing from '../private/landing/Landing'
import Clients from '../private/clients/Clients'
import Messages from '../private/messages/Messages'
import Slides from '../private/slides/Slides'
import Talents from '../private/talents/Talents'
import News from '../private/news/News'
import Pages from "../private/Pages/Pages"

export default class App extends React.Component {
  state = {
    unreadMessages: 0
  }

  componentDidMount = () => {
    this.reloadUnreadCount()
  }

  reloadUnreadCount = () => {
    if (isAuthorized()) {
      api.get(`${apiUrl()}/admin/messages/unread_count`)
        .then((response) => {
          const { count } = response.data
          this.setState({ unreadMessages: count })
        })
    }
  }

  render = () => {
    const unread = this.state.unreadMessages

    return <div className="app">
      <Router>
        <Switch>
          <Route exact path="/"><Redirect to="/works" /></Route>
          <Route path="/login"><Login /></Route>
          <PrivateRoute path="/slides" Component={ <Slides /> } unreadMessages={ unread } />
          <PrivateRoute path="/news" Component={ <News /> } unreadMessages={ unread } />
          <PrivateRoute path="/info" Component={ <Info /> } unreadMessages={ unread } />
          <PrivateRoute path="/works" Component={ <Works /> } unreadMessages={ unread } />
          <PrivateRoute path="/landing" Component={ <Landing /> } unreadMessages={ unread } />
          <PrivateRoute path="/tags" Component={ <Tags /> } unreadMessages={ unread } />
          <PrivateRoute path="/clients" Component={ <Clients /> } unreadMessages={ unread } />
          <PrivateRoute path="/talents" Component={ <Talents /> } unreadMessages={ unread } />
          <PrivateRoute path="/messages" Component={ <Messages unreadMessages={ unread } onRead={ this.reloadUnreadCount } /> } />
          <PrivateRoute path="/pages" Component={ <Pages unreadMessages={ unread } /> } />
        </Switch>
      </Router>
    </div>
  }
}
