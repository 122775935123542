import React from 'react';
import api from 'auth/api';
import { PageHeader } from 'antd';
import { message } from 'antd';
import { withRouter } from 'react-router-dom';
import { apiUrl } from 'auth/api';
import SlidesForm from '../slides-form/SlidesForm';

const key = "slides-new";

class SlidesNew extends React.Component {
  state = {
    loading: false,
  };

  redirect = () => {
    this.props.history.push('/slides');
  };

  onSubmit = (values) => {
    message.loading({ content: 'Загрузка...', key });
    this.setState({ loading: true });
    api.post(`${apiUrl()}/admin/slides`, { slide: values })
      .then((_response) => {
        this.setState({ loading: false });
        message.success({ content: 'Слайд создан!', key, duration: 2 });
        this.redirect();
      })
      .catch((error) => {
        message.error({ content: 'Не удалось создать Слайд', key, duration: 2 });
        console.log(`Error: ${error}`);
        this.setState({ loading: false });
      });
  };


  render() {
    return <div>
      <PageHeader title="Назад к списку" onBack={this.redirect}/>
      <SlidesForm onSubmit={this.onSubmit} />
    </div>
  }
}

export default withRouter(SlidesNew);
