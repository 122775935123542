import React from 'react'
import api from 'auth/api'
import { PageHeader } from 'antd'
import { withRouter } from 'react-router-dom'
import { apiUrl } from 'auth/api'

import InfoForm from '../info-form/InfoForm'

class InfoEdit extends React.Component {
  state = {
    data: null,
    loading: false,
  }

  componentDidMount() {
    this.setState({ loading: true })

    api.get(`${apiUrl()}/admin/info/show`)
      .then((response) => {
        this.setState({ loading: false, data: response.data })
      })
      .catch((error) => {
        console.log("Error loading: " + error)
        this.setState({ loading: false, data: null })
      })
  }

  onSubmit = (values) => {
    console.log(values)

    api.put(`${apiUrl()}/admin/info/update`, {
      show: values.show
    })

    this.props.history.push('/')
  }

  render() {
    const { data } = this.state

    return (
      <div>
        <PageHeader
          title="Инфо"
        />

        {data && <InfoForm data={data} onSubmit={this.onSubmit} />}
      </div>
    )
  }
}

export default withRouter(InfoEdit)
