import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Menu, Space, Empty, Dropdown, Badge } from 'antd';
import { EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import Delete from '../works-delete/WorksDelete'

export default ({ work, onItemDeleted }) => {
  const menu = (<Menu>
    <Menu.Item key="0">
      <Delete id={work.id} onItemDeleted={onItemDeleted} />
    </Menu.Item>
  </Menu>);

  const previewImage = (work.previewImage
    ? <img className="list__card-preview-image" alt={work.name} src={work.previewImage} draggable={false} />
    : <Empty description="Нет изображения" image={Empty.PRESENTED_IMAGE_SIMPLE} style={{marginLeft: 0, height: 56}} />
  );

  const extra = (<Dropdown overlay={menu} trigger={['click']}>
    <Button type="link" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
      <EllipsisOutlined />
    </Button>
  </Dropdown>);

  const editLink = <Link to={`/works/${work.id}/edit`}><div><EditOutlined key="edit" /></div>&nbsp;Основное</Link>;
  const addContentLink = <Link to={`/works/${work.id}/add_content`}><div><EditOutlined key="edit" /></div>&nbsp;Контент</Link>;

  return (<Space direction="vertical" style={ {marginRight: 16, marginBottom: 16} }>
    <Badge dot count={work.showOnFeatured ? 1 : 0}>
      <Card
        hoverable
        cover={previewImage}
        title={work.name}
        style={{ width: 220 }}
        actions={[editLink, addContentLink]}
        bodyStyle={{height: 0, padding: 0, margin: 0}}
        extra={extra}
      />
    </Badge>
  </Space>);
};
