import React from 'react';
import 'antd/dist/antd.css';
import { message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './VideoUploader.css';
import { authHeader } from 'auth/api';
import getBase64 from 'image/get_base_64';

export default class VideoUploader extends React.Component {
  state = {
    loading: false,
    file: null,
    video: null,
    percent: 0
  };

  componentDidMount() {
    this.setState({
      video: this.props.video
    })
  }

  render() {
    const { loading, video, percent } = this.state;

    const { action } = this.props;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>
          {percent === 0 ? (
            <span>Загрузить</span>
          ) : (
            <React.Fragment>
              Загрузка
              <br />
              {percent.toFixed(2)}%
            </React.Fragment>
          )}
        </div>
      </div>
    );

    return (
      <Upload
        name="video"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        headers={ { "Authorization": authHeader() }}
        action={action}
        onChange={this.handleChange}
        accept='.mov,video/mp4,video/x-m4v,audio/*,video/*'
      >
        {video ? <video className="image-uploader__preview" src={video} alt="Превью Видео" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    );
  }

  handleChange = info => {
    this.setState({ video: null })

    if (info.event) {
      this.setState({ percent: info.event.percent })
    }

    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (video) => {
        this.setState({
          video,
          loading: false,
        })
      });
    }
  };
}
