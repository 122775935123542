import React from 'react';
import api from 'auth/api';
import { PageHeader } from 'antd';
import WorksForm from '../works-form/WorksForm';
import { message } from 'antd';
import { withRouter } from 'react-router-dom';
import { apiUrl } from 'auth/api';

const key = "works-edit";

class WorksEdit extends React.Component {
  state = {
    loading: false,
    item: null,
  };

  redirect = () => {
    this.props.history.push('/works');
  };

  componentDidMount() {
    const { work_id } = this.props.match.params;
    this.setState({ loading: true });
    api.get(`${apiUrl()}/admin/works/${work_id}`)
      .then((response) => {
        this.setState({ loading: false, item: response.data.item });
      })
      .catch((error) => {
        console.log("Error loading: " + error);
        this.setState({ loading: false, item: null });
      });
  }

  onSubmit = (values) => {
    const { work_id } = this.props.match.params;
    const newValues = { ...values, client_id: values.client.key };

    message.loading({ content: 'Загрузка...', key });
    this.setState({ loading: true });

    api.put(`${apiUrl()}/admin/works/${work_id}`, { work: newValues })
      .then((_response) => {
        this.setState({ loading: false });
        message.success({ content: 'Работа обновлена!', key, duration: 2 });
        this.redirect();
      })
      .catch((_error) => {
        message.error({ content: 'Не удалось обновить работу!', key, duration: 2 });
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <div>
        <PageHeader title="Редактирование работы" />
        {this.state.item && <WorksForm loading={this.state.loading} onSubmit={this.onSubmit} item={this.state.item} />}
      </div>
    )
  }
}

export default withRouter(WorksEdit);
