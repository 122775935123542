import React from 'react'
import { Upload, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { authHeader } from 'auth/api';

import beforeUpload from "../../../helpers/beforeUpload"

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

class PicturesWallUploader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: props.fileList
    }
  }

  handleCancel = () => this.setState({ previewVisible: false })

  // TODO: do we need that?...
  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  handleChange = ({ fileList }) => {
    this.setState({ fileList })

    this.props.onChange(fileList)
  }

  render() {
    const { images } = this.props
    const { previewVisible, previewImage, fileList = [], previewTitle } = this.state

    const { imgQuantity, action } = this.props
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={ { marginTop: 8 } }>Upload</div>
      </div>
    )

    return (
      <>
        <Upload
          accept="image/*"
          name={this.props.name}
          action={action}
          listType="picture-card"
          fileList={ fileList }
          headers={ { "Authorization": authHeader() }}
          onPreview={ this.handlePreview }
          onChange={ this.handleChange }
          beforeUpload={ beforeUpload }
          onRemove={ this.props.onRemove }
          multiple
        >
          { fileList.length >= imgQuantity ? null : uploadButton }
        </Upload>

        <Modal
          visible={ previewVisible }
          title={ previewTitle }
          footer={ null }
          onCancel={ this.handleCancel }
        >
          <img alt="example" style={ { width: '100%' } } src={ previewImage } />
        </Modal>
      </>
    )
  }
}

PicturesWallUploader.defaultProps = {
  imgQuantity: 2
}

export default PicturesWallUploader
