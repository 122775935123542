import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Table } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { MenuOutlined, EditOutlined } from '@ant-design/icons';

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

export default class WorksDragTable extends React.Component {
  state = { items: [] }

  componentDidMount() {
    this.setState({ items: this.props.items });
  }

  render = () => {
    const { items } = this.state;
    const columns = [
      {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
      },
      {
        title: 'Превью',
        key: 'image',
        render: (item) => item.previewImage && <img className="freeze-frames-uploader__preview-td" src={item.previewImage} alt={item.name} />,
      },
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
        render: text => <span>{text}</span>,
      },
      {
        title: 'Действия',
        key: 'action',
        render: (item) => (
          <Space size="middle">
            <Link key="edit" to={`/works/${item.id}/edit`}><EditOutlined key="edit" />&nbsp;Редактировать</Link>
          </Space>
        ),
      },
    ];

    const DraggableContainer = props => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );

    return <Table
      rowKey="id"
      columns={columns}
      dataSource={items}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: this.DraggableBodyRow,
        },
      }}
      locale={{emptyText: "Нет слайдов"}}
      pagination={false}
    />
  }

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { items } = this.state;
    const index = items.findIndex(x => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { items } = this.state;
    if (oldIndex !== newIndex) {
      const newItems = arrayMove([].concat(items), oldIndex, newIndex).filter(el => !!el);
      this.setState({ items: newItems });
      const newPositions = newItems.map((value, index) => ({ id: value.id, index }));
      this.props.onSortEnd(newPositions);
    }
  };
}
