import React from 'react';
import { Form, Input, Button, Checkbox, Select, Spin, Radio } from 'antd';
import './WorksForm.css';
import ImageUploader from '../image-uploader/ImageUploader';
import api, { apiUrl } from 'auth/api';
import talentRoles from 'models/talent-roles';

const layout = {
  wrapperCol: {
    span: 16,
  },
};

const { TextArea } = Input;
const { Option, OptGroup } = Select;

export default class WorksForm extends React.Component {
  state = {
    workCurrentValues: {}, // для определения отображения некоторых инпутом в зависимости от выбранного kind
    item: null,
    previewImage: null,
    tags: [],
    clientOptions: [],
    nextWorkOptions: [],
    nextWorkOptionsFetching: false,
  };

  componentDidMount() {
    this.setState({ workCurrentValues: this.getInitialValues() });

    api.get(`${apiUrl()}/admin/tags`).then((response) => {
      const tags = response.data.items;
      this.setState({ tags });
    });

    api.get(`${apiUrl()}/admin/clients`).then((response) => {
      const clients = response.data.items;
      this.setState({ clients });
    });

    api.get(`${apiUrl()}/admin/talents`).then((response) => {
      const talents = response.data.items;
      const talentsInGroups = talents.reduce((acc, talent) => {
        acc[talent.role] = acc[talent.role] || [];
        acc[talent.role].push(talent);

        return acc;
      }, {});
      this.setState({ talents: talentsInGroups });
    })

    if (this.state.item && this.state.item.nextWork) {
      this.setState({
        nextWorkOptions: [this.state.item.nextWork]
      });
    }
  }

  findYearOptions = () => {
    const y = +(new Date().getFullYear());
    return [y,y-1,y-2,y-3,y-4,y-5,y-6,y-7,y-8,y-9,y-10,y-11,y-12,y-13,y-14,y-15,y-16,y-17,y-18,y-19,y-20];  // LOL
  }

  fetchNextWorks = (searchName) => {
    this.setState({ nextWorkOptionsFetching: true });
    api.get(`${apiUrl()}/admin/works`, { params: { page: 1, perPage: 50, searchName } })
      .then((response) => {
        const { items } = response.data;
        this.setState({ nextWorkOptionsFetching: false, nextWorkOptions: items });
      })
      .catch((error) => {
        console.log("Error nextWorkOptions: " + error);
        this.setState({ nextWorkOptionsFetching: false, nextWorkOptions: [] });
      });
  }

  fetchClients = (searchName) => {
    this.setState({ clientOptionsFetching: true });
    api.get(`${apiUrl()}/admin/clients`, { params: { page: 1, perPage: 50, searchName } })
      .then((response) => {
        const { items } = response.data;
        this.setState({ clientOptionsFetching: false, clientOptions: items });
      })
      .catch((error) => {
        console.log("Error clientOptions: " + error);
        this.setState({ clientOptionsFetching: false, clientOptions: [] });
      });
  }

  getInitialValues = () => {
    const item = this.props.item;
    return {
      name: item ? item.name : null,
      nameEn: item ? item.nameEn : null,
      kind: item ? item.kind : "video",
      category: item ? item.category : "commercial",
      shortDescription: item ? item.shortDescription : null,
      shortDescriptionEn: item ? item.shortDescriptionEn : null,
      fullDescription: item ? item.fullDescription : null,
      fullDescriptionEn: item ? item.fullDescriptionEn : null,
      url: item ? item.url : null,
      agencyName: item ? item.agencyName : null,
      agencyUrl: item ? item.agencyUrl : null,
      year: item ? item.year : null,
      nextWork: item && item.nextWork ? { label: `${item.nextWork.name} [${item.nextWork.year}]`, value: item.nextWork.id } : { label: "Не выбрана", value: null },
      client: item && item.client ? { label: item.client.name, value: item.client.id } : { label: "Не выбран", value: null },
      archived: item ? item.archived : false,
      showOnFeatured: item ? item.showOnFeatured : false,
      tagIds: item ? item.tags.map(t => t.id) : [],
      talentIds: item ? item.talents.map(t => t.id) : [],
    };
  }

  render() {
    const item = this.props.item;
    const initialValues = this.getInitialValues();
    const yearOptions = this.findYearOptions();

    return (
      <Form
        {...layout}
        name="basic"
        layout="vertical"
        initialValues={initialValues}
        className="form"
        onFinish={this.onFinish}
        onValuesChange={this.onValuesChange}
      >
        <Form.Item
          label="Название работы"
          name="name"
          rules={[
            {
              required: true,
              message: 'Введите название работы',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="English: Название работы"
          name="nameEn"
          rules={[
            {
              required: true,
              message: 'Введите название работы на английском',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Тип работы" name="kind">
          <Radio.Group>
            <Radio.Button value={'photo'}>Фото</Radio.Button>
            <Radio.Button value={'video'}>Видео</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Категория" name="category">
          <Radio.Group style={{ width: '100%' }} placeholder="Выберите категорию">
            <Radio.Button value="commercial">Commercial</Radio.Button>
            <Radio.Button value="photoshoot">Photo</Radio.Button>
            <Radio.Button value="music_video">Music video</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {this.isCreating() &&
          <Form.Item
            label="Превью изображение"
            name="previewImage"
            rules={[
              {
                required: true,
                message: 'Работа не может быть без Превью',
              },
            ]}
          >
            <Input type="file" onChange={ (e) => this.handleChange(e.target.files) } />
          </Form.Item>
        }

        {this.isEditing() &&
          <div className="works-form__preview-item">
            <div className="works-form__preview-label">Превью изображение</div>
            <ImageUploader image={item.previewImage} action={`${apiUrl()}/admin/works/${item.id}/update_preview_image`} />
          </div>
        }

        <Form.Item
          rows={4}
          label="Короткое описание"
          name="shortDescription"
          rules={[
            {
              required: true,
              message: 'Работа не может быть без краткого описания',
            },
          ]}
        >
          <TextArea rows="4" />
        </Form.Item>

        <Form.Item
          rows={4}
          label="English: Короткое описание"
          name="shortDescriptionEn"
          rules={[
            {
              required: true,
              message: 'Работа не может быть без краткого описания',
            },
          ]}
        >
          <TextArea rows="4" />
        </Form.Item>

        <Form.Item
          rows={8}
          label="Полное описание"
          name="fullDescription"
        >
          <TextArea rows={10} />
        </Form.Item>

        <Form.Item
          rows={8}
          label="English: Полное описание"
          name="fullDescriptionEn"
        >
          <TextArea rows={10} />
        </Form.Item>

        <Form.Item
          label="Ссылка на видео"
          name="url"
          rules={[
            {
              message: 'Введите ссылку на видео',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Теги"
          name="tagIds"
        >
          <Select mode="multiple" allowClear style={{ width: '100%' }} placeholder="Выберите теги для Работы">
            {this.state.tags && this.state.tags.map(tag => (
              <Option key={tag.id} value={tag.id}>{tag.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Таланты"
          name="talentIds"
        >
          <Select mode="multiple" allowClear style={{ width: '100%' }} placeholder="Добавить Таланты к Работе">
            {this.state.talents && Object.values(this.state.talents).map((talentGroup, index) => (
              <OptGroup key={index} label={talentRoles[talentGroup[0].role] || talentGroup[0].role}>
                {talentGroup.map((talent) => <Option key={talent.id} value={talent.id}>{talent.name}</Option>)}
              </OptGroup>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Название агенства"
          name="agencyName"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Ссылка на агенство (можно оставить пустым)"
          name="agencyUrl"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Год выпуска"
          name="year"
          rules={[
            {
              required: true,
              message: 'Введите год создания работы',
            },
          ]}
        >
          <Select style={{ width: '100%' }} placeholder="Выберите категорию">
            {yearOptions.map(year => <Option key={year} value={year}>{ year }</Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          label="Следующая работа (показывается в NextWork на странице работы)"
          name="nextWork"
        >
          <Select
            labelInValue
            placeholder="Выберите работу"
            notFoundContent={this.state.nextWorkOptionsFetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={this.fetchNextWorks}
            showSearch
            style={{ width: '100%' }}
          >
            {this.state.nextWorkOptions && this.state.nextWorkOptions.map(d => (
              <Option key={d.id}>{d.name} [{d.year}]</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Клиент (начните печатать имя, чтобы увидеть опции)"
          name="client"
        >
          <Select
            labelInValue
            placeholder="Выберите клиента"
            notFoundContent={this.state.clientOptionsFetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={this.fetchClients}
            showSearch
            style={{ width: '100%' }}
          >
            {this.state.clientOptions && this.state.clientOptions.map(d => (
              <Option key={d.id}>{d.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="showOnFeatured" valuePropName="checked">
          <Checkbox>Показать в разделе "Featured" на главной</Checkbox>
        </Form.Item>

        <Form.Item name="archived" valuePropName="checked">
          <Checkbox>В архиве (Скрыт со всех страниц)</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={this.props.loading}>
            {this.isCreating() ? "Создать" : "Обновить"}
          </Button>
        </Form.Item>
      </Form>)
  }

  onFinish = (values) => {
    const nextWorkId = values.nextWork ? values.nextWork.value : null;
    const clientId = values.client ? values.client.value : null;
    const toSubmit = this.isCreating() ? { ...values, previewImageDataUri: this.state.previewImage, nextWorkId, clientId } : values;
    this.props.onSubmit(toSubmit);
  };

  onValuesChange = (values) => {
    this.setState({ workCurrentValues: values });
  }

  getFiles = (files) => {
    this.setState({ files: files })
  }

  handleChange = (selectorFiles) => {
    this.toBase64(selectorFiles[0]).then((previewImage) => {
      this.setState({ previewImage });
    });
  };

  toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  isEditing() {
    return this.props.item && this.props.item.id;
  }

  isCreating() {
    return !this.isEditing();
  }
}
