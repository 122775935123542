import React from 'react';
import { Modal, Button, Form, Radio, Input } from 'antd';
import ImageUploader from '../../image-uploader/ImageUploader';
import { apiUrl } from 'auth/api';

export default class PhotoTilesModal extends React.Component {
  formRef = React.createRef();

  state = {
    loading: false,
    images: {image1: null, image2: null},
    values: {}
  }

  initialValues = () => {
    const { modalItem } = this.props;
    return {
      kind: modalItem ? modalItem.kind : 'big',
    };
  }

  componentDidMount = () => {
    this.onValuesChange(this.initialValues());
  }

  render() {
    let { modalItem } = this.props;
    const initialValues = this.initialValues();

    const image1 = modalItem && modalItem.image1Url;
    const image2 = modalItem && modalItem.image2Url;

    return (
      <Modal
        title="Ячейка для изображений"
        visible={!!modalItem}
        onCancel={this.props.onModalHide}
        footer={false}
      >
        <Form
          ref={this.formRef}
          name="freeze-frames-form"
          onFinish={this.onFinish}
          onValuesChange={this.onValuesChange}
          layout="vertical"
          initialValues={initialValues}
        >
          <Form.Item label="Тип ячейки" name="kind">
            <Radio.Group>
              <Radio.Button value={'big'}>1 большое фото в ячейке</Radio.Button>
              <Radio.Button value={'small'}>2 маленьких фото в ячейке</Radio.Button>
            </Radio.Group>
          </Form.Item>

          {this.isCreating() &&
            <Form.Item
              label="Фото 1"
              name="image1"
              rules={[
                {
                  required: true,
                  message: 'Нужно хотя бы одно фото для создания ячейки',
                },
              ]}
            >
              <Input type="file" onChange={ (e) => this.handleChange(e.target.files, 'image1') } />
            </Form.Item>
          }

          {this.isEditing() &&
            <div className="works-form__preview-item">
              <div className="works-form__preview-label">Фото 1</div>
              <ImageUploader image={image1} action={`${apiUrl()}/admin/photo_tiles/${modalItem.id}/update_image_1`} />
            </div>
          }

          {this.isCreating() && !this.isBig() &&
            <Form.Item
              label="Фото 2"
              name="image2"
            >
              <Input type="file" onChange={ (e) => this.handleChange(e.target.files, 'image2') } />
            </Form.Item>
          }

          {this.isEditing() && !this.isBig() &&
            <div className="works-form__preview-item">
              <div className="works-form__preview-label">Фото 2</div>
              <ImageUploader image={image2} action={`${apiUrl()}/admin/photo_tiles/${modalItem.id}/update_image_2`} />
            </div>
          }

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={this.state.loading}>
              {this.isCreating() ? "Добавить" : "Обновить"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  isCreating = () => {
    return this.props.modalItem && !this.props.modalItem.id;
  }

  isEditing = () => {
    return this.props.modalItem && !!this.props.modalItem.id;
  }

  isBig = () => {
    return this.state.values && this.state.values.kind === 'big';
  }

  onFinish = (values) => {
    const images = this.state.images;
    this.setState({
      images: null
    })
    this.formRef.current.resetFields();
    const toSubmit = this.isCreating() ? { ...values, image1DataUri: images.image1, image2DataUri: images.image2 } : values;
    delete toSubmit.image1;
    delete toSubmit.image2;
    this.props.onModalSave(this.props.modalItem.id, toSubmit);
  }

  handleChange(selectorFiles, imageName) {
    this.toBase64(selectorFiles[0]).then((image) => {
      const toSet = {images: {...this.state.images, [imageName]: image}};
      this.setState(toSet);
    });
  }

  // TODO: fix copypaste
  toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  onValuesChange = (values) => {
    this.setState({ values: { ...this.state.values, ...values } });
  }
}
