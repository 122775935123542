import React from 'react';
import 'antd/dist/antd.css';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import api, { apiUrl, authHeader } from 'auth/api';
import getBase64 from 'image/get_base_64';

export default class WorkPicturesUploader extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
  };

  componentDidMount = () => {
    const toPictureInUploader = (picture) => ({uid: picture.id, name: 'image.png', status: 'done', url: picture.imageUrl});
    const fileList = (this.props.items || []).map(toPictureInUploader);
    this.setState({ fileList });
  }

  render() {
    const { workId } = this.props;
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Добавить</div>
      </div>
    );

    return (
      <>
        <Upload
          name="image"
          action={`${apiUrl()}/admin/works/${workId}/add_picture`}
          headers={ { "Authorization": authHeader() }}
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          onRemove={this.onRemove}
        >
          {uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.hidePreviewModal}
        >
          <img alt="Изображение" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    );
  }

  hidePreviewModal = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = ({ fileList }) => {
    this.setState({ fileList: fileList.slice() });
  }

  onRemove = (value) => {
    const pictureId = value.uid;
    api.delete(`${apiUrl()}/admin/pictures/${pictureId}`, {}).then((_result) => { });
  }
}
