import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import WorksList from './works-list/WorksList';
import WorksNew from './works-new/WorksNew';
import WorksEdit from './works-edit/WorksEdit';
import WorksAddContent from './add-content/works-add-content/WorksAddContent';

export default function Works() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <WorksList />
      </Route>
      <Route path={`${path}/new`}>
        <WorksNew />
      </Route>
      <Route path={`${path}/:work_id/edit`}>
        <WorksEdit />
      </Route>
      <Route path={`${path}/:work_id/add_content`}>
        <WorksAddContent />
      </Route>
    </Switch>
  )
}
