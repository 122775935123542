import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import FeaturedOrder from './featured-order/FeaturedOrder';


export default function Landing() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/featured_order`}>
        <FeaturedOrder />
      </Route>
    </Switch>
  )
}
