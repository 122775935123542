import React from 'react';
import { Form, Input, Button } from 'antd';
import './SlidesForm.css';
import ImageUploader from '../../works/image-uploader/ImageUploader';
import VideoUploader from '../video-uploader/VideoUploader';
import { apiUrl } from 'auth/api';

const layout = {
  wrapperCol: {
    span: 16,
  },
};

export default class SlidesForm extends React.Component {
  state = {
    item: null,
    image: null,
  };

  render() {
    const item = this.props.item;
    const initialValues = {
      name: item ? item.name : null,
      nameEn: item ? item.nameEn : null,
      videoUrl: item ? item.videoUrl : null,
      link: item ? item.link : null,
      linkText: item ? item.linkText : null,
      linkTextEn: item ? item.linkTextEn : null,
    };

    return (
      <Form
        {...layout}
        name="basic"
        layout="vertical"
        initialValues={initialValues}
        className="form"
        onFinish={this.onFinish}
      >
        <Form.Item
          label="Название слайда"
          name="name"
          rules={[
            {
              required: true,
              message: 'Введите название слайда',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="English: Название слайда"
          name="nameEn"
          rules={[
            {
              required: true,
              message: 'Введите название слайда на английском',
            },
          ]}
        >
          <Input />
        </Form.Item>

        {this.isCreating() &&
          <Form.Item
            label="Превью изображение"
            name="image"
            rules={[
              {
                required: true,
                message: 'Слайд не может быть без Превью',
              },
            ]}
          >
            <Input type="file" onChange={ (e) => this.handleChange(e.target.files) } />
          </Form.Item>
        }

        {this.isEditing() &&
          <div className="works-form__preview-item">
            <div className="works-form__preview-label">Превью изображение</div>
            <ImageUploader image={item.image} action={`${apiUrl()}/admin/slides/${item.id}/update_preview_image`} />
          </div>
        }

        {this.isEditing() &&
          <Form.Item
            label="Загрузите видео"
            name="videoDataUri"
            rules={[
              {
                required: false,
                message: 'Зарузите ваше видео',
              },
            ]}
          >
            <VideoUploader video={item.videoUrl} action={`${apiUrl()}/admin/slides/${item.id}/update_video`} />
          </Form.Item>
        }

        <Form.Item
          label="Текст нижней ссылки"
          name="linkText"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="English: Текст нижней ссылки"
          name="linkTextEn"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Url нижней ссылки"
          name="link"
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={this.props.loading}>
            {this.isCreating() ? "Создать" : "Обновить"}
          </Button>
        </Form.Item>
      </Form>
    )
  }

  onFinish = (values) => {
    const toSubmit = this.isCreating() ? { ...values, previewImageDataUri: this.state.previewImage } : values;
    this.props.onSubmit(toSubmit);
  };

  isEditing() {
    return this.props.item && this.props.item.id;
  }

  isCreating() {
    return !this.isEditing();
  }

  handleChange = (selectorFiles) => {
    this.toBase64(selectorFiles[0]).then((previewImage) => {
      this.setState({ previewImage });
    });
  };

  toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
