import React from 'react'
import api, { apiUrl } from 'auth/api'
import { Input, Form, Card, Button, message } from 'antd'
import { withRouter } from 'react-router-dom'
import './Login.css'
import login from 'auth/login'

const key = 'login'

class Login extends React.Component {
  state = {
    loading: false,
    items: null,
  };

  onFinish = (values) => {
    const { history } = this.props

    this.setState({ loading: true })
    api.post(`${apiUrl()}/admin/sessions`, { auth: values })
      .then((response) => {
        login(response.data.jwt)

        this.setState({ loading: false })
        message.success({ content: 'Вы вошли в систему', key, duration: 2 })
        history.push('/')
      })
      .catch((error) => {
        if (!error || !error.response) {
          this.setState({ loading: false })
          return
        }
        let errorText = ''
        if (error.response.status === 422 || error.response.status === 404) {
          errorText = 'Неправильный логин/пароль'
        } else {
          errorText = 'Что-то пошло не так'
        }
        message.error({ content: errorText, key, duration: 2 })
        console.log(`Error: ${error}`)
        this.setState({ loading: false })
      })
  };

  render() {
    return (
      <Card title="Войти в систему" className="login__card">
        <Form
          style={ {padding: 0} }
          name="basic"
          layout="vertical"
          className="form"
          onFinish={ this.onFinish }
        >
          <Form.Item
            label="Email"
            name="email"
            rules={ [
              {
                required: true,
                message: 'Введите Email',
              },
            ] }
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label="Пароль"
            name="password"
            rules={ [
              {
                required: true,
                message: 'Введите пароль',
              },
            ] }
          >
            <Input type="password" />
          </Form.Item>

          <Form.Item style={ {marginBottom: 0} }>
            <Button type="primary" htmlType="submit" disabled={ this.state.loading }>
              Войти
            </Button>
          </Form.Item>
        </Form>
      </Card>
    )
  }
}

export default withRouter(Login)
