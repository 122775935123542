import React from 'react'
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom"

import ElementsList from "./elements-list/ElementsList"

export default function Pages() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={ path }>
        <ElementsList />
      </Route>
    </Switch>
  )
}
