import React from 'react';
import 'antd/dist/antd.css';
import { message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './ImageUploader.css';
import { authHeader } from 'auth/api';
import getBase64 from 'image/get_base_64';

import beforeUpload from "../../../helpers/beforeUpload"

export default class ImageUploader extends React.Component {
  state = {
    loading: false,
    file: null,
    image: null,
  };

  componentDidMount() {
    this.setState({
      image: this.props.image
    })
  }

  render() {
    const { loading, image } = this.state;
    const { action } = this.props;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Загрузить</div>
      </div>
    );
    return (
      <Upload
        accept="image/*"
        name="image"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        headers={ { "Authorization": authHeader() }}
        action={action}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
        {image ? <img className="image-uploader__preview" src={image} alt="Превью Работы" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    );
  }

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, image =>
        this.setState({
          image,
          loading: false,
        }),
      );
    }
  };
}
