import React  from 'react';
import 'antd/dist/antd.css';
import { Space, Button, Table, Modal, message  } from 'antd';
import { MenuOutlined, ExclamationCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import api, { apiUrl } from 'auth/api';
import PhotoTilesModal from '../photo-tiles-modal/PhotoTilesModal';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const key = "photo-tiles-uploader";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

export default class PhotoTilesUploader extends React.Component {
  state = {
    items: null,
    modalItem: null,
    loading: false
  };

  componentDidMount = () => {
    this.setState({ items: this.props.items });
  }

  render() {
    const { items } = this.state;

    const columns = [
      {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
      },
      {
        title: 'Превью',
        key: 'image',
        render: (item) => <div key={item.id}>
          { item.image1Url && <img className="freeze-frames-uploader__preview-td" src={item.image1Url} alt="Фото 1" /> }
          { (item.kind === 'small' && item.image2Url) && <img className="freeze-frames-uploader__preview-td" src={item.image2Url} alt="Фото 2" /> }
        </div>,
      },
      {
        title: 'Действия',
        key: 'action',
        render: (item) => (
          <Space size="middle">
            <Button onClick={() => this.onItemEdit(item)}><EditOutlined /></Button>
            <Button onClick={() => this.onItemDelete(item)}><DeleteOutlined /></Button>
          </Space>
        ),
      },
    ];

    const DraggableContainer = props => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );

    return <>
      {items && <div>
        <PhotoTilesModal
          key={this.state.modalItem ? this.state.modalItem.id : null}
          modalItem={this.state.modalItem}
          loading={this.state.loading}
          onModalHide={this.onModalHide}
          onModalSave={this.onModalSave}
        ></PhotoTilesModal>
        <Button onClick={this.onItemAdd} type="primary" style={{ marginBottom: 16 }}>
          Добавить ячейку с изображениями
        </Button>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={items}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: this.DraggableBodyRow,
            },
          }}
          locale={{emptyText: "Нет ни одной ячейку с изображениями"}}
          pagination={false}
        />
      </div>}
    </>
  }

  onItemDeleted = (item) => {
    api.delete(`${apiUrl()}/admin/photo_tiles/${item.id}`, { })
      .then((_response) => {
        this.setState({ loading: false });
        message.success({ content: 'Ячейка с изображениями успешно удалена!', key, duration: 2 });
        this.onReload();
      })
      .catch((_error) => {
        message.error({ content: 'Не удалось удалить ячейку с изображениями!', key, duration: 2 });
        this.setState({ loading: false });
      });
  }

  onItemAdd = () => {
    this.setState({ modalItem: { id: null, kind: 'big' } });
  }

  onItemEdit = (item) => {
    this.setState({ modalItem: item });
  }

  onItemDelete = (item) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: <span>Вы точно хотите удалить ячейку с изображениями?</span>,
      onOk: () => this.onItemDeleted(item),
      cancelText: "Нет, не удалять",
      okText: "Да, удалить"
    });
  }

  onModalHide = () => {
    this.setState({ modalItem: null });
  }

  onModalSave = (id, values) => {
    const workId = this.props.workId;
    values = {...values, workId};

    if (!!id) {
      api.put(`${apiUrl()}/admin/photo_tiles/${id}`, { photo_tile: values })
        .then((_response) => {
          this.setState({ loading: false, modalItem: null });
          message.success({ content: 'Ячейка с изображениями успешно сохранена!', key, duration: 2 });
          this.onReload();
        })
        .catch((_error) => {
          message.error({ content: 'Не удалось сохранить ячейку с изображениями!', key, duration: 2 });
          this.setState({ loading: false, modalItem: null });
          this.onReload();
        });
    } else {
      api.post(`${apiUrl()}/admin/photo_tiles`, { photo_tile: values })
        .then((_response) => {
          message.success({ content: 'Ячейка с изображениями успешно создана!', key, duration: 2 });
          this.setState({ loading: false, modalItem: null });
          this.onReload();
        })
        .catch((_error) => {
          message.error({ content: 'Не удалось сохранить ячейку с изображениями!', key, duration: 2 });
          this.setState({ loading: false, modalItem: null });
          this.onReload();
        });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { items } = this.state;
    if (oldIndex !== newIndex) {
      const newItems = arrayMove([].concat(items), oldIndex, newIndex).filter(el => !!el);
      this.setState({ items: newItems });
      const newPositions = newItems.map((value, index) => ({ id: value.id, index }));
      this.updateReorderedPositions(newPositions);
    }
  };

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { items } = this.state;
    const index = items.findIndex(x => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  onReload = () => {
    api.get(`${apiUrl()}/admin/works/${this.props.workId}`)
      .then((response) => {
        this.setState({ items: response.data.item.photoTiles });
      })
      .catch((error) => {
        console.log("Error: " + error);
        this.setState({ items: null });
      });
  }

  updateReorderedPositions = (newPositions) => {
    api.put(`${apiUrl()}/admin/photo_tiles/reorder`, { workId: this.props.workId, newPositions }).then((_response) => {
      message.success({ content: 'Порядок изменен', key, duration: 1 });
    });
  }

  toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
