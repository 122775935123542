import React from 'react';
import WorkPicturesUploader from '../work-pictures-uploader/WorkPicturesUploader';
import FreezeFramesUploader from '../freeze-frames-uploader/FreezeFramesUploader';
import PhotoTilesUploader from '../photo-tiles-uploader/PhotoTilesUploader';
import { Typography } from 'antd';
import './WorksContentForm.css';

const { Title } = Typography;

export default class WorksContentForm extends React.Component {
  state = {
    loading: false,
    item: null,
  };

  render() {
    const { item } = this.props;

    return <>
      {this.isVideo() &&
        <div className="works-content-form__preview-item">
          <Title level={5}>Стоп-кадры</Title>
          <FreezeFramesUploader workId={item.id} items={item.freezeFrames} />
        </div>}
      {this.isVideo() &&
        <div className="works-content-form__preview-item">
          <Title level={5}>Изображения</Title>
          <WorkPicturesUploader workId={item.id} items={item.pictures} />
        </div>}

      {this.isPhoto() &&
        <div className="works-content-form__preview-item">
          <Title level={5}>Фотографии</Title>
          <PhotoTilesUploader workId={item.id} items={item.photoTiles} />
        </div>}
    </>;
  }

  isVideo() {
    const { item } = this.props;
    return item.kind === 'video';
  }

  isPhoto() {
    const { item } = this.props;
    return item.kind === 'photo';
  }
}
