import React  from 'react';
import 'antd/dist/antd.css';
import { Space, Button, Table, Modal, message  } from 'antd';
import { MenuOutlined, ExclamationCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import api, { apiUrl } from 'auth/api';
import FreezeFramesModal from '../freeze-frames-modal/FreezeFramesModal';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import './FreezeFramesUploader.css';

const key = "freeze-frame-uploader";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

export default class FreezeFramesUploader extends React.Component {
  state = {
    items: null,
    modalItem: null,
    loading: false
  };

  componentDidMount = () => {
    this.setState({ items: this.props.items });
  }

  render() {
    const { items } = this.state;

    const columns = [
      {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
      },
      {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name',
        render: text => <>{text}</>,
      },
      {
        title: 'Превью',
        key: 'image',
        render: (item) => (<img className="freeze-frames-uploader__preview-td" src={item.imageUrl} alt={item.name} />),
      },
      {
        title: 'Действия',
        key: 'action',
        render: (item) => (
          <Space size="middle">
            <Button onClick={() => this.onItemEdit(item)}><EditOutlined /></Button>
            <Button onClick={() => this.onItemDelete(item)}><DeleteOutlined /></Button>
          </Space>
        ),
      },
    ];

    const DraggableContainer = props => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );

    return items && <div>
      {this.state.modalItem && <FreezeFramesModal
        modalItem={this.state.modalItem}
        loading={this.state.loading}
        onModalHide={this.onModalHide}
        onModalSave={this.onModalSave}
      ></FreezeFramesModal>}
      <Button onClick={this.onItemAdd} type="primary" style={{ marginBottom: 16 }}>
        Добавить стоп-кадр
      </Button>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={items}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: this.DraggableBodyRow,
          },
        }}
        locale={{emptyText: "Нет ни одного стоп-кадра"}}
        pagination={false}
      />
    </div>;
  }

  onItemDeleted = (item) => {
    api.delete(`${apiUrl()}/admin/freeze_frames/${item.id}`, { })
      .then((_response) => {
        this.setState({ loading: false });
        message.success({ content: 'Стоп-кадр успешно удален!', key, duration: 2 });
        this.onReload();
      })
      .catch((_error) => {
        message.error({ content: 'Не удалось удалить стоп-кадр!', key, duration: 2 });
        this.setState({ loading: false });
      });
  }

  onItemAdd = () => {
    this.setState({ modalItem: { id: null } });
  }

  onItemEdit = (item) => {
    this.setState({ modalItem: item });
  }

  onItemDelete = (item) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: <span>Вы точно хотите удалить стоп-кадр?</span>,
      onOk: () => {
        this.onItemDeleted(item);
      },
      cancelText: "Нет, не удалять",
      okText: "Да, удалить"
    });
  }

  onModalHide = () => {
    this.setState({ modalItem: null });
  }

  onModalSave = (id, values) => {
    values = {...values, workId: this.props.workId};

    if (!!id) {
      api.put(`${apiUrl()}/admin/freeze_frames/${id}`, { freeze_frame: values })
        .then((_response) => {
          this.setState({ loading: false, modalItem: null });
          message.success({ content: 'Стоп-кадр успешно сохранен!', key, duration: 2 });
          this.onReload();
        })
        .catch((_error) => {
          message.error({ content: 'Не удалось сохранить стоп-кадр!', key, duration: 2 });
          this.setState({ loading: false, modalItem: null });
          this.onReload();
        });
    } else {
      api.post(`${apiUrl()}/admin/freeze_frames`, { freeze_frame: values })
        .then((_response) => {
          message.success({ content: 'Стоп-кадр успешно создан!', key, duration: 2 });
          this.setState({ loading: false, modalItem: null });
          this.onReload();
        })
        .catch((_error) => {
          message.error({ content: 'Не удалось сохранить стоп-кадр!', key, duration: 2 });
          this.setState({ loading: false, modalItem: null });
          this.onReload();
        });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { items } = this.state;
    if (oldIndex !== newIndex) {
      const newItems = arrayMove([].concat(items), oldIndex, newIndex).filter(el => !!el);
      this.setState({ items: newItems });
      const newPositions = newItems.map((value, index) => ({ id: value.id, index }));
      this.updateReorderedPositions(newPositions);
    }
  };

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { items } = this.state;
    const index = items.findIndex(x => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  onReload = () => {
    api.get(`${apiUrl()}/admin/works/${this.props.workId}`)
      .then((response) => {
        this.setState({ items: response.data.item.freezeFrames });
      })
      .catch((error) => {
        console.log("Error: " + error);
        this.setState({ items: null });
      });
  }

  updateReorderedPositions = (newPositions) => {
    api.put(`${apiUrl()}/admin/freeze_frames/reorder`, { workId: this.props.workId, newPositions }).then((_response) => {
      message.success({ content: 'Порядок изменен', key, duration: 1 });
    });
  }
}
