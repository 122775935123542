import React, { useEffect, useState } from 'react'
import { Table, Switch, PageHeader, } from 'antd'
import api, { apiUrl } from "../../../auth/api"

const setPageVisability = (bool, id) => {
  try {
    api.put(`${ apiUrl() }/pages/${ id }`, { show: bool })
  } catch (e) {
    console.log(e)
  }
}

function handleSwitchChange(e, checked) {
  setPageVisability(e, checked.id)
}

const columns = [
  {
    title: "Елемент",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Скрыть / показать",
    dataIndex: "show",
    key: "isActive",
    render: (e, record) => (
      <Switch onChange={ (e) => handleSwitchChange(e, record) } defaultChecked={ e } />
    )
  },
]

const ElementsList = () => {
  const [tableData, setData] = useState([])

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`${ apiUrl() }/pages/`)
      const sortData = data.sort((a, b) => a.id - b.id)
      setData(sortData)
    }

    fetchData()
  }, tableData)

  return (
    <React.Fragment>
      <PageHeader
        title="Скрытие страниц"
      />
      <Table columns={ columns } dataSource={ tableData } pagination={ false } />
    </React.Fragment>
  )
}

export default ElementsList
