import { message } from 'antd'

function beforeUpload(file) {
  const isLt10M = file.size / 1024 / 1024 < 10

  if (!isLt10M) {
    message.error('Не грузите изображения больше 10MB!')
  }

  return isLt10M
}

export default beforeUpload
