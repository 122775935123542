import React from 'react';
import api, { apiUrl } from 'auth/api'
import { PageHeader, Table, Pagination, Modal, Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import './MessagesList.css';

export default class MessagesList extends React.Component {
  state = {
    loading: false,
    items: null,
    totalItems: null,
    page: 1,
    perPage: 20,
    openItem: null,
  };

  componentDidMount() {
    this.loadMessages(this.state);
  }

  render() {
    const openItem = this.state.openItem;
    const modal = <Modal
      title={openItem ? openItem.kindTranslated : `Сообщение`}
      visible={!!openItem}
      onCancel={this.closeModal}
      footer={[
        <Button key="close" type="button" onClick={this.closeModal}>Закрыть</Button>,
      ]}
    >
      <div><MailOutlined />&nbsp;<b>{openItem && openItem.email}</b> в {openItem && openItem.createdAt}</div>
      {openItem && openItem.detailedKind && <div>
        <span>Тип запроса: {openItem.kindTranslated}</span>
      </div>}
      {openItem && openItem.link && <div>
        <span>Ссылка: </span><a href={openItem.link} target="_blank" rel="noopener noreferrer">{openItem.link}</a>
      </div>}
      <div className="messages-list__message-body">{openItem && openItem.message}</div>
    </Modal>;

    const columns = [
      {
        title: 'Email',
        key: 'email',
        render: item => (
          <span className={!item.read ? 'messages-list__table-item_read' : ''}>{item.email}</span>
        )
      },
      {
        title: 'Тип',
        dataIndex: 'kindTranslated',
        key: 'kindTranslated',
        render: text => <>{text}</>,
      },
      {
        title: 'Время написания',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: text => <>{text}</>,
      },
    ];
    const { items, totalItems } = this.state;
    return (<div>
      <PageHeader title="Обращения от пользователей" />
      {this.state.loading && (
        <div>Загрузка...</div>
      )}
      <Table rowKey="id" columns={columns} dataSource={items} locale={{emptyText: "Нет сообщений"}} pagination={false}
        onRow={(record) => {
          return {
            onClick: () => this.showMessage(record),
          };
        }}/>

      {items && <Pagination
        style={{marginTop: 12}}
        showSizeChanger
        onChange={this.onChangePage}
        pageSize={this.state.perPage}
        defaultCurrent={1}
        total={totalItems}
      />}

      {modal}
    </div>)
  }

  onChangePage = (page, perPage) => {
    this.setState({ page, perPage }, (_state) => {
      this.loadMessages(this.state);
    });
  }

  markMessageAsRead = (item) => {
    api.put(`${apiUrl()}/admin/messages/${item.id}/mark_read`, { })
      .then((_response) => {
        this.setState({ loading: false });
        this.loadMessages(this.state);
        this.props.onRead();
      })
      .catch((_error) => {
        this.setState({ loading: false });
      });
  }

  loadMessages = (state) => {
    this.setState({ loading: true });
    const { page, perPage } = state;

    api.get(`${apiUrl()}/admin/messages`, { params: { page, perPage } })
      .then((response) => {
        const { items, totalItems, } = response.data;
        this.setState({ loading: false, items, totalItems });
      })
      .catch((error) => {
        console.log("Error loading: " + error);
        this.setState({ loading: false, items: null });
      });
  }

  showMessage = (item) => {
    this.setState({ openItem: item }, () => this.markMessageAsRead(item));
  }

  closeModal = () => {
    this.setState({
      openItem: null,
    })
  }
}
