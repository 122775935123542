import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import ClientsList from './clients-list/ClientsList';
import ClientsNew from './clients-new/ClientsNew';


export default function Clients() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ClientsList />
      </Route>
      <Route path={`${path}/new`}>
        <ClientsNew />
      </Route>
    </Switch>
  )
}
