import React from 'react';
import api from 'auth/api';
import { PageHeader } from 'antd';
import { message, Form, Input, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { apiUrl } from 'auth/api';

const key = "tags-new";

class TagsNew extends React.Component {
  state = {
    loading: false,
    value: '',
    valueEn: '',
  };

  redirect = () => {
    this.props.history.push('/tags');
  };

  onChange = (e) => {
    this.setState({ value: e.target.value });
  }

  onChangeEn = (e) => {
    this.setState({ valueEn: e.target.value });
  }

  onSubmit = () => {
    message.loading({ content: 'Загрузка...', key });
    this.setState({ loading: true });
    api.post(`${apiUrl()}/admin/tags`, { tag: { name: this.state.value, nameEn: this.state.valueEn } })
      .then((_response) => {
        this.setState({ loading: false });
        message.success({ content: 'Тег создан!', key, duration: 2 });
        this.redirect();
      })
      .catch((error) => {
        message.error({ content: 'Не удалось создать тег', key, duration: 2 });
        console.log(`Error: ${error}`);
        this.setState({ loading: false });
      });
  };


  render() {
    return (
      <div>
        <PageHeader title="Назад к списку" onBack={this.redirect} />
          <Form name="Создать новый тег" onFinish={this.onSubmit}>

            <span>Имя тега:</span>
            <Form.Item name="tangame" rules={[{ required: true, message: 'Имя не может быть пустым' }]}>
              <Input style={{ width: '60%', marginTop: '10px' }} value={this.state.value} onChange={this.onChange}/>
            </Form.Item>

            <br/>

            <span>English - Имя тега:</span>
            <Form.Item name="tangame2" rules={[{ required: true, message: 'Имя не может быть пустым' }]}>
              <Input style={{ width: '60%', marginTop: '10px' }} value={this.state.valueEn} onChange={this.onChangeEn}/>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ marginLeft: '6px' }}>
                Создать
              </Button>

            </Form.Item>
          </Form>
      </div>
    )
  }
}

export default withRouter(TagsNew);
